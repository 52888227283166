var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", { class: _vm.$style.pageTitle }, [_vm._v("違反マップ")]),
    _c("div", { staticClass: "card", class: _vm.$style.map }, [
      _c("div", { class: _vm.$style.content }, [
        _c("div", { class: _vm.$style.contentWrapper }, [
          _c(
            "div",
            {
              staticClass: "card-body",
              staticStyle: { padding: "0 !important" }
            },
            [
              _c("div", [
                _vm.mapLoading
                  ? _c(
                      "div",
                      {
                        staticClass: "text-center",
                        staticStyle: {
                          width: "100%",
                          height: "80vh",
                          "padding-top": "200px",
                          "margin-bottom": "5000px"
                        }
                      },
                      [_c("a-spin", { attrs: { tip: "Loading..." } })],
                      1
                    )
                  : _vm._e()
              ]),
              _c(
                "GmapMap",
                {
                  ref: "gMap",
                  style: _vm.gMapStyle,
                  attrs: {
                    center: { lat: 35.517007, lng: 139.613112 },
                    zoom: 15,
                    "map-type-id": "roadmap",
                    options: {
                      styles: _vm.mapStyle,
                      streetViewControl: true,
                      disableDefaultUi: true,
                      scaleControl: true,
                      zoomControl: true
                    }
                  },
                  on: {
                    rightclick: function($event) {
                      return _vm.setGps($event)
                    }
                  }
                },
                [
                  _vm.isClusterUse
                    ? _c(
                        "div",
                        [
                          _c(
                            "GmapCluster",
                            _vm._l(_vm.markersDriving, function(m) {
                              return _c("GmapMarker", {
                                key: m.id,
                                attrs: {
                                  position: m.position,
                                  clickable: true,
                                  draggable: false,
                                  icon: m.icon
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isClusterUse
                    ? _c(
                        "div",
                        _vm._l(_vm.markersDriving, function(m) {
                          return _c("GmapMarker", {
                            key: m.id,
                            attrs: {
                              position: m.position,
                              clickable: true,
                              draggable: false,
                              icon: m.icon
                            }
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm.showMakerPointList
                    ? _c(
                        "div",
                        _vm._l(_vm.markersPointList, function(m) {
                          return _c("GmapMarker", {
                            key: m.lat,
                            attrs: {
                              position: m,
                              clickable: true,
                              draggable: false,
                              icon:
                                "https://maps.google.com/mapfiles/ms/icons/red-dot.png"
                            }
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm.showMakerNameList
                    ? _c(
                        "div",
                        _vm._l(_vm.markersNameList, function(m) {
                          return _c(
                            "GmapMarker",
                            {
                              key: m.lat,
                              attrs: {
                                position: m,
                                clickable: true,
                                draggable: false,
                                icon:
                                  "https://maps.google.com/mapfiles/ms/icons/green-dot.png"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handleMarkerNameClick(m)
                                }
                              }
                            },
                            [
                              _c(
                                "GmapInfoWindow",
                                {
                                  attrs: {
                                    position: _vm.clickedMarkerPosition,
                                    options: {
                                      pixelOffset: { width: 0, height: -10 },
                                      minWidth: 120
                                    },
                                    opened: _vm.clickedMarkerName === m.name
                                  },
                                  on: { closeclick: _vm.handleCloseInfoWindow }
                                },
                                [_c("div", [_vm._v(_vm._s(m.name))])]
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm.searchMarker && _vm.showSearchMaker
                    ? _c(
                        "div",
                        [
                          _c("GmapMarker", {
                            attrs: {
                              position: _vm.searchMarker,
                              clickable: true,
                              draggable: false,
                              icon:
                                "https://maps.google.com/mapfiles/ms/icons/blue-dot.png"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.searchRectangle && _vm.showSearchRectangle
                    ? _c(
                        "div",
                        [
                          _c("GmapPolygon", {
                            attrs: {
                              paths: _vm.searchRectangle,
                              strokeColor: "#0000FF",
                              strokeOpacity: 0.8,
                              strokeWeight: 2,
                              fillColor: "#0000FF",
                              fillOpacity: 0.15
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            ],
            1
          )
        ])
      ]),
      _c("div", { class: _vm.$style.sidebar }, [
        _c("div", { class: _vm.$style.sidebarHeader }, [
          _c("h2", { class: _vm.$style.cardTitle }, [_vm._v("フィルター")]),
          _c(
            "div",
            {
              class: _vm.$style.selectorContainer,
              staticStyle: { height: "auto" }
            },
            [
              _vm._v(
                "\n          取得件数: " + _vm._s(_vm.total) + " 件\n          "
              ),
              _c(
                "a-button",
                {
                  class: _vm.$style.filledBtn,
                  staticStyle: { "margin-left": "30px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.updateMonitor }
                },
                [_vm._v("更新")]
              )
            ],
            1
          )
        ]),
        _vm.monitorLoading
          ? _c(
              "div",
              {
                staticClass: "text-center",
                staticStyle: { "padding-top": "200px" }
              },
              [_c("a-spin", { attrs: { tip: "Loading..." } })],
              1
            )
          : _vm._e(),
        !_vm.monitorLoading
          ? _c(
              "div",
              {
                class: _vm.$style.tabs,
                staticStyle: { "padding-left": "15px" }
              },
              [
                !_vm.loading
                  ? _c(
                      "a-form-model",
                      {
                        attrs: {
                          "label-col": _vm.labelCol,
                          "wrapper-col": _vm.wrapperCol
                        }
                      },
                      [
                        _c("h4", { staticClass: "filter_title" }, [
                          _vm._v("日付")
                        ]),
                        _c(
                          "a-config-provider",
                          { attrs: { locale: _vm.locale } },
                          [
                            _c(
                              "a-form-model-item",
                              {
                                ref: "date_start",
                                attrs: { label: "開始日", prop: "date_start" }
                              },
                              [
                                _c("a-date-picker", {
                                  on: { change: _vm.changeDateStart },
                                  model: {
                                    value: _vm.dateStart,
                                    callback: function($$v) {
                                      _vm.dateStart = $$v
                                    },
                                    expression: "dateStart"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "a-config-provider",
                          { attrs: { locale: _vm.locale } },
                          [
                            _c(
                              "a-form-model-item",
                              {
                                ref: "date_end",
                                attrs: { label: "終了日", prop: "date_end" }
                              },
                              [
                                _c("a-date-picker", {
                                  on: { change: _vm.changeDateEnd },
                                  model: {
                                    value: _vm.dateEnd,
                                    callback: function($$v) {
                                      _vm.dateEnd = $$v
                                    },
                                    expression: "dateEnd"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "a-form-model-item",
                          {
                            ref: "week",
                            attrs: { label: "曜日", prop: "week" }
                          },
                          [
                            _c(
                              "a-checkbox-group",
                              {
                                class: _vm.$style.excViolationCheckList,
                                model: {
                                  value: _vm.formData.week,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "week", $$v)
                                  },
                                  expression: "formData.week"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "row" },
                                  _vm._l(_vm.weeks, function(item) {
                                    return _c(
                                      "div",
                                      { key: item.value },
                                      [
                                        _c(
                                          "a-checkbox",
                                          { attrs: { value: item.value } },
                                          [_vm._v(_vm._s(item.label))]
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c("h4", { staticClass: "filter_title" }, [
                          _vm._v("時刻")
                        ]),
                        _c(
                          "a-form-model-item",
                          {
                            ref: "time_start",
                            attrs: { label: "開始時刻", prop: "time_start" }
                          },
                          [
                            _c("a-time-picker", {
                              attrs: { format: "HH:mm" },
                              on: { change: _vm.changeTimeStart },
                              model: {
                                value: _vm.timeStart,
                                callback: function($$v) {
                                  _vm.timeStart = $$v
                                },
                                expression: "timeStart"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "a-form-model-item",
                          {
                            ref: "time_end",
                            attrs: { label: "終了時刻", prop: "time_end" }
                          },
                          [
                            _c("a-time-picker", {
                              attrs: { format: "HH:mm" },
                              on: { change: _vm.changeTimeEnd },
                              model: {
                                value: _vm.timeEnd,
                                callback: function($$v) {
                                  _vm.timeEnd = $$v
                                },
                                expression: "timeEnd"
                              }
                            })
                          ],
                          1
                        ),
                        _c("h4", { staticClass: "filter_title" }, [
                          _vm._v("違反種別")
                        ]),
                        _c(
                          "a-form-model-item",
                          {
                            ref: "type",
                            attrs: { label: "違反種別", prop: "type" }
                          },
                          [
                            _c(
                              "a-checkbox-group",
                              {
                                class: _vm.$style.excViolationCheckList,
                                model: {
                                  value: _vm.formData.type,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "type", $$v)
                                  },
                                  expression: "formData.type"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "row" },
                                  _vm._l(_vm.types, function(item) {
                                    return _c(
                                      "div",
                                      { key: item.value },
                                      [
                                        _vm.role === 0 ||
                                        (item.value !== 8 && item.value !== 9)
                                          ? _c(
                                              "a-checkbox",
                                              {
                                                attrs: {
                                                  disabled: item.disabled,
                                                  value: item.value
                                                }
                                              },
                                              [_vm._v(_vm._s(item.label))]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c("h4", { staticClass: "filter_title" }, [
                          _vm._v("道路種別")
                        ]),
                        _c(
                          "a-form-model-item",
                          {
                            ref: "roadType",
                            attrs: { label: "道路種別", prop: "roadType" }
                          },
                          [
                            _c(
                              "a-checkbox-group",
                              {
                                class: _vm.$style.excViolationCheckList,
                                model: {
                                  value: _vm.formData.place,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "place", $$v)
                                  },
                                  expression: "formData.place"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "row" },
                                  _vm._l(_vm.roadTypes, function(item) {
                                    return _c(
                                      "div",
                                      { key: item.value },
                                      [
                                        _c(
                                          "a-checkbox",
                                          { attrs: { value: item.value } },
                                          [_vm._v(_vm._s(item.label))]
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c("h4", { staticClass: "filter_title" }, [
                          _vm._v("緯度経度指定（範囲）")
                        ]),
                        _c(
                          "a-form-model-item",
                          {
                            ref: "latitude",
                            attrs: { label: "緯度", prop: "latitude" }
                          },
                          [
                            _c("a-input-number", {
                              attrs: { min: 0, max: 100 },
                              model: {
                                value: _vm.formData.latitude,
                                callback: function($$v) {
                                  _vm.$set(_vm.formData, "latitude", $$v)
                                },
                                expression: "formData.latitude"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "a-form-model-item",
                          {
                            ref: "longitude",
                            attrs: {
                              label: "経度",
                              prop: "longitude",
                              extra: "地図を右クリック/下の検索で設定可"
                            }
                          },
                          [
                            _c("a-input-number", {
                              attrs: { min: 0, max: 500 },
                              model: {
                                value: _vm.formData.longitude,
                                callback: function($$v) {
                                  _vm.$set(_vm.formData, "longitude", $$v)
                                },
                                expression: "formData.longitude"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "a-form-model-item",
                          {
                            ref: "search_query",
                            attrs: { label: "検索", prop: "search_query" }
                          },
                          [
                            _c("a-input", {
                              attrs: { placeholder: "場所の名前を入力" },
                              model: {
                                value: _vm.searchQuery,
                                callback: function($$v) {
                                  _vm.searchQuery = $$v
                                },
                                expression: "searchQuery"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "a-row",
                          [
                            _c("a-col", { attrs: { span: 14, offset: 8 } }, [
                              _c(
                                "p",
                                { staticClass: "text-right" },
                                [
                                  _c(
                                    "a-button",
                                    {
                                      class: _vm.$style.filledBtn,
                                      on: { click: _vm.searchPlace }
                                    },
                                    [_vm._v("位置検索")]
                                  )
                                ],
                                1
                              )
                            ])
                          ],
                          1
                        ),
                        _c(
                          "a-form-model-item",
                          {
                            ref: "range",
                            attrs: {
                              label: "範囲（km）",
                              prop: "range",
                              extra: "最大300"
                            }
                          },
                          [
                            _c("a-input-number", {
                              attrs: { min: 1, max: 300 },
                              model: {
                                value: _vm.formData.range,
                                callback: function($$v) {
                                  _vm.$set(_vm.formData, "range", $$v)
                                },
                                expression: "formData.range"
                              }
                            })
                          ],
                          1
                        ),
                        _c("h4", { staticClass: "filter_title" }, [
                          _vm._v("緯度経度指定（矩形）")
                        ]),
                        _c("p", [
                          _vm._v(
                            "※ 矩形検索の値が全て入力されている場合は、矩形検索が優先されます。"
                          )
                        ]),
                        _c(
                          "p",
                          { staticClass: "text-center" },
                          [
                            _c(
                              "a-button",
                              {
                                class: _vm.$style.filledBtn,
                                on: { click: _vm.setRectangleStart }
                              },
                              [_vm._v("矩形検索設定")]
                            )
                          ],
                          1
                        ),
                        _c("p", [
                          _vm._v(
                            "上記のボタンをクリック後、指定したい範囲の左上、右下を順に右クリックすることで設定できます。"
                          )
                        ]),
                        _c(
                          "a-form-model-item",
                          {
                            ref: "latLeftTop",
                            attrs: { label: "緯度（左上）", prop: "latLeftTop" }
                          },
                          [
                            _c("a-input-number", {
                              attrs: { min: 0, max: 100 },
                              model: {
                                value: _vm.formData.latLeftTop,
                                callback: function($$v) {
                                  _vm.$set(_vm.formData, "latLeftTop", $$v)
                                },
                                expression: "formData.latLeftTop"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "a-form-model-item",
                          {
                            ref: "lngLeftTop",
                            attrs: { label: "経度（左上）", prop: "lngLeftTop" }
                          },
                          [
                            _c("a-input-number", {
                              attrs: { min: 0, max: 500 },
                              model: {
                                value: _vm.formData.lngLeftTop,
                                callback: function($$v) {
                                  _vm.$set(_vm.formData, "lngLeftTop", $$v)
                                },
                                expression: "formData.lngLeftTop"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "a-form-model-item",
                          {
                            ref: "latRightDown",
                            attrs: {
                              label: "緯度（右下）",
                              prop: "latRightDown"
                            }
                          },
                          [
                            _c("a-input-number", {
                              attrs: { min: 0, max: 100 },
                              model: {
                                value: _vm.formData.latRightDown,
                                callback: function($$v) {
                                  _vm.$set(_vm.formData, "latRightDown", $$v)
                                },
                                expression: "formData.latRightDown"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "a-form-model-item",
                          {
                            ref: "lngRightDown",
                            attrs: {
                              label: "経度（右下）",
                              prop: "lngRightDown"
                            }
                          },
                          [
                            _c("a-input-number", {
                              attrs: { min: 0, max: 500 },
                              model: {
                                value: _vm.formData.lngRightDown,
                                callback: function($$v) {
                                  _vm.$set(_vm.formData, "lngRightDown", $$v)
                                },
                                expression: "formData.lngRightDown"
                              }
                            })
                          ],
                          1
                        ),
                        _c("h4", { staticClass: "filter_title" }, [
                          _vm._v("取得件数")
                        ]),
                        _c(
                          "a-form-model-item",
                          {
                            ref: "limit",
                            attrs: {
                              label: "最大取得件数",
                              prop: "limit",
                              extra: "最大50000"
                            }
                          },
                          [
                            _c("a-input-number", {
                              attrs: { min: 1, max: 50000 },
                              model: {
                                value: _vm.formData.limit,
                                callback: function($$v) {
                                  _vm.$set(_vm.formData, "limit", $$v)
                                },
                                expression: "formData.limit"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "p",
                          { staticClass: "text-left" },
                          [
                            _c(
                              "a-button",
                              {
                                class: _vm.$style.filledBtn,
                                attrs: { type: "primary" },
                                on: { click: _vm.updateMonitor }
                              },
                              [_vm._v("更新")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          : _vm._e(),
        _c("hr", { staticStyle: { margin: "30px 0 10px" } }),
        _c("div", { staticStyle: { "margin-bottom": "40px" } }, [
          _c("h2", { class: _vm.$style.cardTitle }, [_vm._v("表示設定")]),
          _c(
            "div",
            { staticStyle: { "padding-left": "15px" } },
            [
              _c("h4", { staticClass: "filter_title" }, [
                _vm._v("違反の表示方法")
              ]),
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { attrs: { span: 20, offset: 2 } },
                    [
                      _c(
                        "a-checkbox",
                        {
                          model: {
                            value: _vm.isClusterUse,
                            callback: function($$v) {
                              _vm.isClusterUse = $$v
                            },
                            expression: "isClusterUse"
                          }
                        },
                        [_vm._v("クラスターとして表示")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("h4", { staticClass: "filter_title" }, [
                _vm._v("ピンを立てる場所")
              ]),
              !_vm.loadingShow
                ? _c(
                    "a-form-model",
                    {
                      attrs: {
                        "label-col": _vm.labelDisplayCol,
                        "wrapper-col": _vm.wrapperDisplayCol
                      }
                    },
                    [
                      _c("img", {
                        staticStyle: { float: "left" },
                        attrs: {
                          src:
                            "https://maps.google.com/mapfiles/ms/icons/green-dot.png"
                        }
                      }),
                      _c(
                        "a-form-model-item",
                        {
                          ref: "locationNameList",
                          attrs: {
                            label: "位置名",
                            prop: "locationNameList",
                            extra: "位置名を改行で記入"
                          }
                        },
                        [
                          _c("a-textarea", {
                            model: {
                              value: _vm.locationNameList,
                              callback: function($$v) {
                                _vm.locationNameList = $$v
                              },
                              expression: "locationNameList"
                            }
                          })
                        ],
                        1
                      ),
                      _c("img", {
                        staticStyle: { float: "left" },
                        attrs: {
                          src:
                            "https://maps.google.com/mapfiles/ms/icons/red-dot.png"
                        }
                      }),
                      _c(
                        "a-form-model-item",
                        {
                          ref: "locationPointList",
                          attrs: {
                            label: "緯度経度",
                            prop: "locationPointList",
                            extra: "緯度経度の順でカンマ区切りで改行で記入"
                          }
                        },
                        [
                          _c("a-textarea", {
                            model: {
                              value: _vm.locationPointList,
                              callback: function($$v) {
                                _vm.locationPointList = $$v
                              },
                              expression: "locationPointList"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "p",
                        { staticClass: "text-center" },
                        [
                          _c(
                            "a-button",
                            {
                              class: _vm.$style.filledBtn,
                              on: { click: _vm.setPins }
                            },
                            [_vm._v("ピンを立てる")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("h4", { staticClass: "filter_title" }, [
                _vm._v("地図上の表示")
              ]),
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { attrs: { span: 20, offset: 2 } },
                    [
                      _c(
                        "a-checkbox",
                        {
                          model: {
                            value: _vm.showSearchMaker,
                            callback: function($$v) {
                              _vm.showSearchMaker = $$v
                            },
                            expression: "showSearchMaker"
                          }
                        },
                        [_vm._v("検索中心点")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 20, offset: 2 } },
                    [
                      _c(
                        "a-checkbox",
                        {
                          model: {
                            value: _vm.showSearchRectangle,
                            callback: function($$v) {
                              _vm.showSearchRectangle = $$v
                            },
                            expression: "showSearchRectangle"
                          }
                        },
                        [_vm._v("検索範囲")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 20, offset: 2 } },
                    [
                      _c(
                        "a-checkbox",
                        {
                          model: {
                            value: _vm.showMakerNameList,
                            callback: function($$v) {
                              _vm.showMakerNameList = $$v
                            },
                            expression: "showMakerNameList"
                          }
                        },
                        [_vm._v("ピン：位置名")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 20, offset: 2 } },
                    [
                      _c(
                        "a-checkbox",
                        {
                          model: {
                            value: _vm.showMakerPointList,
                            callback: function($$v) {
                              _vm.showMakerPointList = $$v
                            },
                            expression: "showMakerPointList"
                          }
                        },
                        [_vm._v("ピン：緯度経度")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }