<template>
  <div>
    <h1 :class="$style.pageTitle">違反マップ</h1>
    <div class="card" :class="$style.map">
      <div :class="$style.content">
        <div :class="$style.contentWrapper">
          <div class="card-body" style="padding: 0 !important">
            <div>
              <div v-if="mapLoading" class="text-center" style="width: 100%; height: 80vh; padding-top: 200px; margin-bottom: 5000px;">
                <a-spin tip="Loading..." />
              </div>
            </div>
            <GmapMap
              ref="gMap"
              :center="{lat:35.517007, lng:139.613112}"
              :zoom="15"
              map-type-id="roadmap"
              :options="{
                styles: mapStyle,
                streetViewControl: true,
                disableDefaultUi: true,
                scaleControl: true,
                zoomControl: true
              }"
              :style="gMapStyle"
              @rightclick="setGps($event)"
            >
              <div v-if="isClusterUse">
                <GmapCluster>
                  <GmapMarker
                    :key="m.id"
                    v-for="m in markersDriving"
                    :position="m.position"
                    :clickable="true"
                    :draggable="false"
                    :icon="m.icon"
                  />
                </GmapCluster>
              </div>
              <div v-if="!isClusterUse">
                <GmapMarker
                  :key="m.id"
                  v-for="m in markersDriving"
                  :position="m.position"
                  :clickable="true"
                  :draggable="false"
                  :icon="m.icon"
                />
              </div>
              <div v-if="showMakerPointList">
                <GmapMarker
                  :key="m.lat"
                  v-for="m in markersPointList"
                  :position="m"
                  :clickable="true"
                  :draggable="false"
                  icon="https://maps.google.com/mapfiles/ms/icons/red-dot.png"
                />
              </div>
              <div v-if="showMakerNameList">
                <GmapMarker
                  :key="m.lat"
                  v-for="m in markersNameList"
                  :position="m"
                  :clickable="true"
                  :draggable="false"
                  icon="https://maps.google.com/mapfiles/ms/icons/green-dot.png"
                  @click="handleMarkerNameClick(m)"
                >
                  <GmapInfoWindow
                    :position="clickedMarkerPosition"
                    :options="{ pixelOffset: { width: 0, height: -10 }, minWidth: 120 }"
                    :opened="clickedMarkerName === m.name"
                    @closeclick="handleCloseInfoWindow"
                  >
                    <div >{{ m.name }}</div>
                  </GmapInfoWindow>
                </GmapMarker>
              </div>
              <div v-if="searchMarker && showSearchMaker">
                <GmapMarker
                  :position="searchMarker"
                  :clickable="true"
                  :draggable="false"
                  icon="https://maps.google.com/mapfiles/ms/icons/blue-dot.png"
                />
              </div>
              <div v-if="searchRectangle && showSearchRectangle">
                <GmapPolygon
                  :paths="searchRectangle"
                  :strokeColor="'#0000FF'"
                  :strokeOpacity="0.8"
                  :strokeWeight="2"
                  :fillColor="'#0000FF'"
                  :fillOpacity="0.15"
                />
              </div>
            </GmapMap>
          </div>
        </div>
      </div>
      <div :class="$style.sidebar">
        <div :class="$style.sidebarHeader">
          <h2 :class="$style.cardTitle">フィルター</h2>
          <div :class="$style.selectorContainer" style="height: auto;">
            取得件数: {{ total }} 件
            <a-button type="primary" @click="updateMonitor" :class="$style.filledBtn" style="margin-left: 30px;">更新</a-button>
          </div>
        </div>
        <div v-if="monitorLoading" class="text-center" style="padding-top: 200px">
          <a-spin tip="Loading..." />
        </div>
        <div v-if="!monitorLoading" :class="$style.tabs" style="padding-left: 15px;">
          <a-form-model
            v-if="!loading"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <h4 class="filter_title">日付</h4>
            <a-config-provider :locale="locale">
              <a-form-model-item ref="date_start" label="開始日" prop="date_start">
                <a-date-picker v-model="dateStart" @change="changeDateStart" />
              </a-form-model-item>
            </a-config-provider>
            <a-config-provider :locale="locale">
              <a-form-model-item ref="date_end" label="終了日" prop="date_end">
                <a-date-picker v-model="dateEnd" @change="changeDateEnd" />
              </a-form-model-item>
            </a-config-provider>
            <a-form-model-item ref="week" label="曜日" prop="week">
              <a-checkbox-group v-model="formData.week" :class="$style.excViolationCheckList">
                <div class="row">
                  <div v-for="item in weeks" v-bind:key="item.value">
                    <a-checkbox :value="item.value">{{item.label}}</a-checkbox>
                  </div>
                </div>
              </a-checkbox-group>
            </a-form-model-item>

            <h4 class="filter_title">時刻</h4>
            <a-form-model-item ref="time_start" label="開始時刻" prop="time_start">
              <a-time-picker v-model="timeStart" format="HH:mm" @change="changeTimeStart" />
            </a-form-model-item>
            <a-form-model-item ref="time_end" label="終了時刻" prop="time_end">
              <a-time-picker v-model="timeEnd" format="HH:mm" @change="changeTimeEnd" />
            </a-form-model-item>

            <h4 class="filter_title">違反種別</h4>
            <a-form-model-item ref="type" label="違反種別" prop="type">
              <a-checkbox-group v-model="formData.type" :class="$style.excViolationCheckList">
                <div class="row">
                  <div v-for="item in types" v-bind:key="item.value">
                    <a-checkbox  v-if="role === 0 || (item.value !== 8 && item.value !== 9)" :disabled="item.disabled" :value="item.value">{{item.label}}</a-checkbox>
                  </div>
                </div>
              </a-checkbox-group>
            </a-form-model-item>

            <h4 class="filter_title">道路種別</h4>
            <a-form-model-item ref="roadType" label="道路種別" prop="roadType">
              <a-checkbox-group v-model="formData.place" :class="$style.excViolationCheckList">
                <div class="row">
                  <div v-for="item in roadTypes" v-bind:key="item.value">
                    <a-checkbox :value="item.value">{{item.label}}</a-checkbox>
                  </div>
                </div>
              </a-checkbox-group>
            </a-form-model-item>

            <h4 class="filter_title">緯度経度指定（範囲）</h4>
            <a-form-model-item ref="latitude" label="緯度" prop="latitude">
              <a-input-number v-model="formData.latitude" :min="0" :max="100"/>
            </a-form-model-item>
            <a-form-model-item ref="longitude" label="経度" prop="longitude" extra="地図を右クリック/下の検索で設定可">
              <a-input-number v-model="formData.longitude" :min="0" :max="500"/>
            </a-form-model-item>
            <a-form-model-item ref="search_query" label="検索" prop="search_query">
              <a-input v-model="searchQuery" placeholder="場所の名前を入力" />
            </a-form-model-item>
            <a-row>
              <a-col :span="14" :offset="8">
                <p class="text-right"><a-button @click="searchPlace" :class="$style.filledBtn">位置検索</a-button></p>
              </a-col>
            </a-row>
            <a-form-model-item ref="range" label="範囲（km）" prop="range" extra="最大300">
              <a-input-number v-model="formData.range" :min="1" :max="300"/>
            </a-form-model-item>

            <h4 class="filter_title">緯度経度指定（矩形）</h4>
            <p>※ 矩形検索の値が全て入力されている場合は、矩形検索が優先されます。</p>
            <p class="text-center"><a-button @click="setRectangleStart" :class="$style.filledBtn">矩形検索設定</a-button></p>
            <p>上記のボタンをクリック後、指定したい範囲の左上、右下を順に右クリックすることで設定できます。</p>
            <a-form-model-item ref="latLeftTop" label="緯度（左上）" prop="latLeftTop">
              <a-input-number v-model="formData.latLeftTop" :min="0" :max="100"/>
            </a-form-model-item>
            <a-form-model-item ref="lngLeftTop" label="経度（左上）" prop="lngLeftTop">
              <a-input-number v-model="formData.lngLeftTop" :min="0" :max="500"/>
            </a-form-model-item>
            <a-form-model-item ref="latRightDown" label="緯度（右下）" prop="latRightDown">
              <a-input-number v-model="formData.latRightDown" :min="0" :max="100"/>
            </a-form-model-item>
            <a-form-model-item ref="lngRightDown" label="経度（右下）" prop="lngRightDown">
              <a-input-number v-model="formData.lngRightDown" :min="0" :max="500"/>
            </a-form-model-item>

            <h4 class="filter_title">取得件数</h4>
            <a-form-model-item ref="limit" label="最大取得件数" prop="limit" extra="最大50000">
              <a-input-number v-model="formData.limit" :min="1" :max="50000"/>
            </a-form-model-item>
            <p class="text-left"><a-button type="primary" @click="updateMonitor" :class="$style.filledBtn">更新</a-button></p>
          </a-form-model>
        </div>
        <hr style="margin: 30px 0 10px;">
        <div style="margin-bottom: 40px;">
          <h2 :class="$style.cardTitle">表示設定</h2>
          <div style="padding-left: 15px;">
            <h4 class="filter_title">違反の表示方法</h4>
            <a-row>
              <a-col :span="20" :offset="2">
                <a-checkbox v-model="isClusterUse">クラスターとして表示</a-checkbox>
              </a-col>
            </a-row>
            <h4 class="filter_title">ピンを立てる場所</h4>
            <a-form-model
              v-if="!loadingShow"
              :label-col="labelDisplayCol"
              :wrapper-col="wrapperDisplayCol"
            >
              <img src="https://maps.google.com/mapfiles/ms/icons/green-dot.png" style="float: left;" />
              <a-form-model-item ref="locationNameList" label="位置名" prop="locationNameList" extra="位置名を改行で記入">
                <a-textarea v-model="locationNameList" />
              </a-form-model-item>

              <img src="https://maps.google.com/mapfiles/ms/icons/red-dot.png" style="float: left;" />
              <a-form-model-item ref="locationPointList" label="緯度経度" prop="locationPointList" extra="緯度経度の順でカンマ区切りで改行で記入">
                <a-textarea v-model="locationPointList" />
              </a-form-model-item>
              <p class="text-center"><a-button @click="setPins" :class="$style.filledBtn">ピンを立てる</a-button></p>
            </a-form-model>

            <h4 class="filter_title">地図上の表示</h4>
            <a-row>
              <a-col :span="20" :offset="2">
                <a-checkbox v-model="showSearchMaker">検索中心点</a-checkbox>
              </a-col>
              <a-col :span="20" :offset="2">
                <a-checkbox v-model="showSearchRectangle">検索範囲</a-checkbox>
              </a-col>
              <a-col :span="20" :offset="2">
                <a-checkbox v-model="showMakerNameList">ピン：位置名</a-checkbox>
              </a-col>
              <a-col :span="20" :offset="2">
                <a-checkbox v-model="showMakerPointList">ピン：緯度経度</a-checkbox>
              </a-col>
            </a-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Vue from 'vue'
import storeVue from '@/store'
import * as VueGoogleMaps from 'vue2-google-maps'
import GmapCluster from 'vue2-google-maps/dist/components/cluster'
import moment from 'moment'
import { mapStyle } from '@/services/mapStyle'
import { iconData } from '@/services/mapIcons'
import { enumData } from '@/services/enum'
import jaJa from 'ant-design-vue/es/locale/ja_JP'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyA-KH18hgZoLsK7-zOueBHrnA-4VZJmQVQ',
    libraries: 'places',
    region: 'JP',
    language: 'ja',
  },
})
Vue.config.productionTip = false

const icon05 = {
  url: 'resources/images/markers/05.png',
  size: { width: 24, height: 24, f: 'px', b: 'px' },
  scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
  anchor: { x: 12, y: 12 },
}
const icon15 = {
  url: 'resources/images/markers/15.png',
  size: { width: 24, height: 24, f: 'px', b: 'px' },
  scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
  anchor: { x: 12, y: 12 },
}

const weeks = [
  { value: 1, label: '日' },
  { value: 2, label: '月' },
  { value: 3, label: '火' },
  { value: 4, label: '水' },
  { value: 5, label: '木' },
  { value: 6, label: '金' },
  { value: 7, label: '土' },
]

export default {
  components: {
    GmapCluster,
  },
  name: 'trial-violations-map',
  data() {
    return {
      locale: jaJa,
      isClusterUse: false,
      role: 3,
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
      labelDisplayCol: { span: 6 },
      wrapperDisplayCol: { span: 14 },
      mapLoading: true,
      monitorLoading: true,
      gMapStyle: 'width: 100%; height: 80vh;',
      loading: false,
      loadingShow: false,
      mapStyle: mapStyle,
      activeMarkerIndex: null,
      gpsData: null,
      markersDriving: [],
      checkStatus: ['0'],
      showStop: false,
      gMap: null,
      bounds: null,
      firstFlag: 1,
      types: enumData.violationType,
      roadTypes: enumData.roadType,
      weeks: weeks,

      today: moment(),
      dateStart: null,
      dateEnd: null,
      timeStart: null,
      timeEnd: null,
      formData: {
        date_start: moment().format('YYYY-MM-01'),
        date_end: moment().format('YYYY-MM-DD'),
        time_start: '',
        time_end: '',
        range: 5,
        limit: 100,
        latitude: null,
        longitude: null,
        latLeftTop: null,
        lngLeftTop: null,
        latRightDown: null,
        lngRightDown: null,
        week: [],
        type: [],
        place: [],
      },
      total: 0,
      searchQuery: '', // フリーワード検索用のデータ
      searchMarker: null, // 検索結果のマーカー用のデータ
      searchRectangle: null, // 検索範囲の四角形用のデータ
      showSearchMaker: true,
      showSearchRectangle: true,
      showMakerNameList: true,
      showMakerPointList: true,

      isRectSetMode: false,

      locationNameList: '',
      locationPointList: '',
      markersNameList: [],
      markersPointList: [],
      clickedMarkerName: null,
      clickedMarkerPosition: null,
    }
  },
  computed: {
    userRole() {
      return storeVue.getters.role
    },
  },
  created() {
    this.updateMonitor()
  },
  mounted() {
    this.$refs.gMap.$mapPromise.then((map) => {
      this.gMap = map
    })
  },
  watch: {
    'formData.range': function(newRange, oldRange) {
      this.setSearchRectangle()
    },
    'formData.latitude': function(newLat, oldLat) {
      this.setSearchRectangle()
    },
    'formData.longitude': function(newLng, oldLng) {
      this.setSearchRectangle()
    },
    userRole(val) {
      this.role = val
    },
  },
  methods: {
    updateMonitor() {
      const _this = this
      this.monitorLoading = true
      var bodyData = {
        date_start: this.formData.date_start,
        date_end: this.formData.date_end,
        week: this.formData.week,
        type: this.formData.type,
        place: this.formData.place,
        limit: this.formData.limit,
        range: this.formData.range,
        latitude: this.formData.latitude,
        longitude: this.formData.longitude,
        lat_left_top: this.formData.latLeftTop,
        lng_left_top: this.formData.lngLeftTop,
        lat_right_down: this.formData.latRightDown,
        lng_right_down: this.formData.lngRightDown,
      }
      if (this.formData.time_start && this.formData.time_end) {
        bodyData.time_start = this.formData.time_start
        bodyData.time_end = this.formData.time_end
      }
      const result = Vue.prototype.$api.send('get', 'master/trial/violation_statistics', bodyData)
      this.markersDriving = []
      this.$gmapApiPromiseLazy().then(() => {
        result.then(response => {
          this.total = response.length
          let bounds = new google.maps.LatLngBounds() // eslint-disable-line
          // console.log('res', response)
          response.forEach(function(val) {
            let markerLon = 0
            let markerLat = 0

            markerLon = val.longitude
            markerLat = val.latitude
            const markerSpeed = val.speed.toFixed(2)
            let markerIcon
            if (val.type <= 5) {
              if (val.limit_speed === 15) markerIcon = icon15
              else if (val.limit_speed === 5) markerIcon = icon05
              else markerIcon = iconData[0][(val.limit_speed / 10) - 1]
            } else {
              markerIcon = iconData[1][val.type - 6]
            }
            _this.markersDriving.push({ position: { lat: markerLat, lng: markerLon }, type: val.type, speed: markerSpeed, icon: markerIcon })

            if (markerLat && markerLon) {
              bounds.extend(new google.maps.LatLng(markerLat, markerLon)) // eslint-disable-line
              _this.bounds = bounds
            }
          })

          if (_this.firstFlag) {
            this.mapLoading = false
            this.gMapStyle = 'width: 100%; height: 80vh;'
            if (this.gMap) {
              if (this.total !== 0) {
                this.gMap.fitBounds(bounds)
              } else {
                this.gMap.setCenter({ lat: 35.517007, lng: 139.613112 })
              }
            }
            _this.firstFlag = 0
          } else {
            if (this.total !== 0) {
              this.gMap.fitBounds(bounds)
            }
          }

          this.monitorLoading = false
        })
          .catch(error => {
            this.$notification['error']({
              message: error.status + ': 許可されていません。',
            })
          })
      })
    },
    changeDateStart(date) {
      if (date) this.formData.date_start = date.format('YYYY-MM-DD')
      else this.formData.date_start = null
    },
    changeDateEnd(date) {
      if (date) this.formData.date_end = date.format('YYYY-MM-DD')
      else this.formData.date_end = null
    },
    changeTimeStart(time) {
      if (time) this.formData.time_start = time.format('HH:mm:00')
      else this.formData.time_start = null
    },
    changeTimeEnd(time) {
      if (time) this.formData.time_end = time.format('HH:mm:00')
      else this.formData.time_end = null
    },
    setGps(event) {
      if (this.isRectSetMode) {
        if (this.formData.latLeftTop && this.formData.lngLeftTop) {
          this.formData.latRightDown = event.latLng.lat()
          this.formData.lngRightDown = event.latLng.lng()
          this.searchMarker = null
          this.isRectSetMode = false
          this.setSearchRectangle()
          this.$notification['success']({
            message: '緯度経度を設定しました。',
          })
        } else {
          this.formData.latLeftTop = event.latLng.lat()
          this.formData.lngLeftTop = event.latLng.lng()
          this.$notification['success']({
            message: '緯度経度（右下）の箇所を右クリックしてください。',
          })
        }
      } else {
        this.formData.latitude = event.latLng.lat()
        this.formData.longitude = event.latLng.lng()
        this.searchMarker = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        }
        this.setSearchRectangle()
        this.$notification['success']({
          message: '緯度経度を設定しました。',
        })
      }
    },
    searchPlace() {
      const geocoder = new google.maps.Geocoder() // eslint-disable-line
      geocoder.geocode({ address: this.searchQuery }, (results, status) => {
        if (status === 'OK' && results[0]) {
          const location = results[0].geometry.location
          const lat = location.lat()
          const lng = location.lng()

          this.center = { lat, lng }
          this.formData.latitude = lat
          this.formData.longitude = lng
          this.searchMarker = { lat, lng }
          this.setSearchRectangle()
          this.$notification['success']({
            message: '緯度経度を設定しました。',
          })
          if (this.gMap) {
            this.gMap.setCenter(location)
          }
        } else {
          this.$notification['error']({
            message: '検索エラー',
            description: '場所が見つかりませんでした。',
          })
        }
      })
    },
    setSearchRectangle() {
      if (this.formData.latLeftTop && this.formData.lngLeftTop && this.formData.latRightDown && this.formData.lngRightDown) {
        this.searchRectangle = [
          { lat: this.formData.latLeftTop, lng: this.formData.lngLeftTop },
          { lat: this.formData.latLeftTop, lng: this.formData.lngRightDown },
          { lat: this.formData.latRightDown, lng: this.formData.lngRightDown },
          { lat: this.formData.latRightDown, lng: this.formData.lngLeftTop },
        ]
      } else {
        const range = this.formData.range
        const lat = this.formData.latitude
        const lng = this.formData.longitude
        const latOffset = range * 0.009
        const lngOffset = range * 0.0125
        this.searchRectangle = [
          { lat: lat + latOffset, lng: lng + lngOffset },
          { lat: lat + latOffset, lng: lng - lngOffset },
          { lat: lat - latOffset, lng: lng - lngOffset },
          { lat: lat - latOffset, lng: lng + lngOffset },
        ]
      }
    },
    resetSearchRectangle() {
      this.searchRectangle = null
    },

    setRectangleStart() {
      this.formData.latLeftTop = null
      this.formData.lngLeftTop = null
      this.formData.latRightDown = null
      this.formData.lngRightDown = null
      this.isRectSetMode = true
      this.resetSearchRectangle()
      this.$notification['success']({
        message: '緯度経度（左上）の箇所を右クリックしてください。',
      })
    },
    handleMarkerNameClick(marker) {
      this.clickedMarkerName = marker.name
    },
    handleCloseInfoWindow() {
      this.clickedMarkerName = null
    },
    setPins() {
      const _this = this
      _this.markersPointList = []
      _this.markersNameList = []
      _this.locationPointList.split(/\n/).forEach(function(point) {
        const latlng = point.split(',')
        _this.markersPointList.push({
          lat: parseFloat(latlng[0]),
          lng: parseFloat(latlng[1]),
        })
      })
      if (_this.locationNameList) {
        const geocoder = new google.maps.Geocoder() // eslint-disable-line
        _this.locationNameList.split(/\n/).forEach(function(locationName) {
          geocoder.geocode({ address: locationName }, (results, status) => {
            if (status === 'OK' && results[0]) {
              const location = results[0].geometry.location
              _this.markersNameList.push({
                lat: location.lat(),
                lng: location.lng(),
                name: locationName,
              })
            } else {
              _this.$notification['error']({
                message: '検索エラー',
                description: locationName + 'が見つかりませんでした。',
              })
            }
          })
        })
      }
      _this.$notification['success']({
        message: 'ピンを設定しました。',
      })
    },
  },
}

</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
<style>
.list-title {
  font-size: 1.5em;
  margin: 10px 0 5px 10px;
  text-align: left;
}
.ant-list-item-meta {
  padding-left: 15px;
}
.ant-list-item.hide {
  background: #d9d9d9;
}
.ant-list-item.active {
  background: #e6f7ff;
}
.ant-form-item {
  margin-bottom: 4px;
}
.filter_title {
  margin-top: 10px;
}
.gm-style-iw-chr {
  height: 0;
  width: 0;
  position: absolute;
  right: 20px;
  top: 2px;
}
.gm-style-iw-chr button {
  height: 0 !important;
  width: 0 !important;
}
.gm-style-iw-chr button span {
  height: 18px !important;
  width: 18px !important;
  margin: 0 !important;
}
.gm-style-iw-d {
  padding-top: 12px;
}
</style>
