export const mapStyle = [{
  featureType: 'landscape',
  elementType: 'geometry',
  stylers: [{
    saturation: '-100',
  }, {
    color: '#dce0e4',
  }],
}, {
  featureType: 'landscape.natural.landcover',
  elementType: 'geometry.fill',
  stylers: [{
    visibility: 'on',
  }],
}, {
  featureType: 'poi',
  elementType: 'geometry.fill',
  stylers: [{
    color: '#a2e29f',
  }],
}, {
  featureType: 'poi',
  elementType: 'labels',
  stylers: [{
    visibility: 'off',
  }],
}, {
  featureType: 'poi',
  elementType: 'labels.text.stroke',
  stylers: [{
    visibility: 'off',
  }],
}, {
  featureType: 'poi.business',
  elementType: 'labels',
  stylers: [{
    visibility: 'off',
  }],
}, {
  featureType: 'poi.business',
  elementType: 'labels.text',
  stylers: [{
    visibility: 'off',
  }],
}, {
  featureType: 'poi.park',
  elementType: 'geometry.fill',
  stylers: [{
    color: '#a2e29f',
  }],
}, {
  featureType: 'poi.school',
  elementType: 'geometry.fill',
  stylers: [{
    color: '#cfd6dc',
  }],
}, {
  featureType: 'poi.sports_complex',
  elementType: 'geometry.fill',
  stylers: [{
    color: '#a2e29f',
  }],
}, {
  featureType: 'road',
  elementType: 'labels',
  stylers: [{
    visibility: 'off',
  }],
}, {
  featureType: 'road',
  elementType: 'labels.text',
  stylers: [{
    color: '#79808a',
  }, {
    weight: '2.08',
  }, {
    visibility: 'off',
  }],
}, {
  featureType: 'road',
  elementType: 'labels.text.stroke',
  stylers: [{
    visibility: 'off',
  }],
}, {
  featureType: 'road.highway',
  elementType: 'all',
  stylers: [{
    visibility: 'off',
  }],
}, {
  featureType: 'road.highway',
  elementType: 'geometry.fill',
  stylers: [{
    saturation: '-87',
  }, {
    lightness: '-40',
  }, {
    color: '#ffffff',
  }, {
    visibility: 'on',
  }],
}, {
  featureType: 'road.highway',
  elementType: 'geometry.stroke',
  stylers: [{
    visibility: 'off',
  }],
}, {
  featureType: 'road.highway.controlled_access',
  elementType: 'geometry.fill',
  stylers: [{
    color: '#f0f0f0',
  }, {
    saturation: '-22',
  }, {
    lightness: '-16',
  }],
}, {
  featureType: 'road.highway.controlled_access',
  elementType: 'geometry.stroke',
  stylers: [{
    visibility: 'off',
  }],
}, {
  featureType: 'road.highway.controlled_access',
  elementType: 'labels.icon',
  stylers: [{
    visibility: 'on',
  }],
}, {
  featureType: 'road.arterial',
  elementType: 'geometry.stroke',
  stylers: [{
    visibility: 'off',
  }],
}, {
  featureType: 'road.local',
  elementType: 'geometry.stroke',
  stylers: [{
    visibility: 'off',
  }],
}, {
  featureType: 'transit.station.bus',
  elementType: 'all',
  stylers: [{
    visibility: 'off',
  }],
}, {
  featureType: 'water',
  elementType: 'geometry',
  stylers: [{
    gamma: '1',
  }, {
    lightness: '-12',
  }, {
    saturation: '-7',
  }],
}, {
  featureType: 'water',
  elementType: 'geometry.fill',
  stylers: [{
    saturation: '-93',
  }, {
    lightness: '-12',
  }, {
    color: '#6dc1d2',
  }, {
    gamma: '1',
  }],
}]
