export const iconData = [
  [
    {
      url: 'resources/images/markers/10.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/20.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/30.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/40.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/50.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/60.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/70.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/80.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/90.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/100.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/110.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/120.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
  ], [
    {
      url: 'resources/images/markers/6.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/7.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/8.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/9.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/10.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/11.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
  ], [
    {
      url: 'resources/images/markers/start.png',
      size: { width: 32, height: 32, f: 'px', b: 'px' },
      scaledSize: { width: 32, height: 32, f: 'px', b: 'px' },
      anchor: { x: 16, y: 32 },
    },
    {
      url: 'resources/images/markers/goal.png',
      size: { width: 32, height: 32, f: 'px', b: 'px' },
      scaledSize: { width: 32, height: 32, f: 'px', b: 'px' },
      anchor: { x: 16, y: 32 },
    },
    {
      url: 'resources/images/markers/announce.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
  ], [
    {
      url: 'resources/images/markers/6a.png',
      aurl: 'resources/images/markers/6a.png',
      size: { width: 36, height: 36, f: 'px', b: 'px' },
      scaledSize: { width: 36, height: 36, f: 'px', b: 'px' },
      anchor: { x: 18, y: 18 },
    },
    {
      url: 'resources/images/markers/7a.png',
      aurl: 'resources/images/markers/7a.png',
      size: { width: 36, height: 36, f: 'px', b: 'px' },
      scaledSize: { width: 36, height: 36, f: 'px', b: 'px' },
      anchor: { x: 18, y: 18 },
    },
    {
      url: 'resources/images/markers/8.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/9.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/10.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/11.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
  ], [
    {
      url: 'resources/images/markers/10a.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/20a.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/30a.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/40a.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/50a.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/60a.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/70a.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/80a.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/90a.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/100a.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/110a.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/120a.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
  ],
]
